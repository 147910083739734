import {
  TRACKING_PRODUCT_COMPONENTS,
  TRACKING_PROMOTION_COMPONENTS,
} from '~/lib/constants'

import {
  SliceManager as AbstractSliceManager,
  SliceManagerProps as AbstractSliceManagerProps,
  CustomSliceProps,
} from '@unlikelystudio/react-abstract-components'

import SliceProvider from '~/providers/SliceProvider'

export type SliceManagerProps = AbstractSliceManagerProps & {
  customStartPosition?: number
  customData?: Record<string, string>[]
} & {
  hasUIDebug?: boolean
  UIDebugClassName?: string
}

function SliceManager(props: SliceManagerProps) {
  let promotionPosition = 0
  let productPosition = 0
  const { customStartPosition = 1 } = props

  return (
    <AbstractSliceManager
      customSlice={({ data, children, index }: CustomSliceProps) => {
        const isPromotion = TRACKING_PROMOTION_COMPONENTS.includes(data?.type)
        const isProduct = TRACKING_PRODUCT_COMPONENTS.includes(data?.type)
        const withCustom = isPromotion || isProduct

        if (isPromotion) promotionPosition++
        if (isProduct) productPosition++

        return withCustom ? (
          <SliceProvider
            value={{
              type: data?.type,
              position: index + customStartPosition,
              isPromotion,
              isProduct,
              promotionPosition,
              productPosition,
              customData: props?.customData,
            }}
            key={index}>
            {props?.hasUIDebug && (
              <code className={props?.UIDebugClassName}>{data?.type}</code>
            )}
            {children}
          </SliceProvider>
        ) : (
          <SliceProvider
            value={{
              type: data?.type,
              position: index + customStartPosition,
              customData: props?.customData,
            }}
            key={index}>
            {props?.hasUIDebug && (
              <code className={props?.UIDebugClassName}>{data?.type}</code>
            )}
            {children}
          </SliceProvider>
        )
      }}
      {...props}
    />
  )
}

export default SliceManager
