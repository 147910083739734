import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import React, { Dispatch, useCallback, useRef, useState } from 'react'
import type { ReactPlayerProps } from 'react-player'
import { useMeasure } from 'react-use'
import screenfull from 'screenfull'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
} from '@unlikelystudio/react-hooks'

import Ratio from '~/components/Abstracts/Ratio'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'
import {
  trackVideoPause,
  trackVideoPlay,
  trackVideoProgress,
  trackVideoProgressEnd,
  trackVideoSeek,
} from '~/components/Abstracts/VideoPlayer/utils'

import { useTracker } from '~/providers/TrackerProvider'

import { useMathFitStyle } from '~/hooks/useMathFit'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const VideoPlayerYoutube = dynamic(
  () => import('~/components/Abstracts/VideoPlayer/VideoPlayerYoutube'),
  {
    ssr: false,
  },
)

export interface VideoPlayerProps extends Omit<ReactPlayerProps, 'onEnded'> {
  className?: string
  videoClassName?: string
  src?: string
  hasPlaysInline?: boolean
  hasEventTracking?: boolean
  isConstrained?: boolean
  isAutoplay?: boolean
  isMuted?: boolean
  theme?: string
  loop?: boolean
  videoName?: string
  onDOMReady?: () => void
  onReady?: () => void
  onEnded?: () => void
  onPause?: () => void
  onPlay?: () => void
  onStart?: () => void
  onFullScreenChange?: () => void
  onExitView?: () => void
  onClick?: (e) => void
  children?: JSX.Element | JSX.Element[]
  ratio?: string
  setVideoState?: Dispatch<React.SetStateAction<VideoState>>
  videoRatio?: number
}

const VideoPlayer = ({
  className,
  videoClassName,
  src,
  children,
  isAutoplay,
  onDOMReady,
  onReady,
  onEnded,
  onPlay,
  onPause,
  onStart,
  onExitView,
  onClick,
  controls,
  hasPlaysInline,
  isConstrained,
  isMuted,
  theme,
  loop,
  ratio,
  videoName,
  hasEventTracking,
  setVideoState,
  videoRatio = 1,
}: VideoPlayerProps) => {
  const playerComponentRef = useRef<HTMLDivElement>()
  const videoRef = useRef(null)
  const { tracker } = useTracker()

  const [videoIsHover, { onMouseEnter, onMouseLeave }] = useIsHover()
  const [videoStarted, setVideoStarted] = useState(true)
  const [videoMuted, setVideoMuted] = useState(isMuted)
  const [videoPaused, setVideoPaused] = useState(true)
  const [videoControls] = useState(controls)
  const [videoProgress, setVideoProgress] = useState(0)
  const [videoDuration, setVideoDuration] = useState(0)
  const [videoIsDOMReady, setVideoIsDOMReady] = useState(false)
  const [videoIsReady, setVideoIsReady] = useState(false)
  const [videoIsLoading, setVideoIsLoading] = useState(false)
  const [videoIsFullScreen, setVideoIsFullScreen] = useState(false)

  const onDurationChange = (duration) => {
    duration && setVideoDuration(duration)
  }

  const videoHandlePausePlay = () => {
    if (!videoStarted) setVideoStarted(true)
    setVideoPaused((prevState) => !prevState)
  }

  const videoHandleMuteUnmute = () => {
    setVideoMuted((prevState) => !prevState)
  }

  const videoHandleSeekTo = (seconds: number) => {
    videoRef?.current?.seekTo(seconds, 'seconds')

    if (hasEventTracking) {
      trackVideoSeek(tracker, { videoName, seconds })
    }
  }

  const videoHandleFullScreen = () => {
    if (videoIsFullScreen) {
      screenfull.exit()
      setVideoIsFullScreen(false)
    } else {
      screenfull.request(playerComponentRef.current)
      setVideoIsFullScreen(true)
    }
  }

  const updateProgress = (timing) => {
    const videoTime = timing?.playedSeconds
    const progress = videoTime / videoDuration

    if (!isNaN(progress) && hasEventTracking && !videoPaused) {
      trackVideoProgress(tracker, { progress, videoName })
    }

    setVideoProgress(progress)
  }

  const onEndedCallback = useCallback(() => {
    setVideoPaused(true)

    if (!isNaN(videoProgress) && hasEventTracking && !videoPaused) {
      trackVideoProgressEnd(tracker, { videoName })
    }

    videoHandleSeekTo(0)
    onEnded?.()
  }, [onEnded, hasEventTracking, videoProgress, videoPaused, videoName])

  const onStartCallback = useCallback(() => {
    setVideoPaused(false)
    onStart?.()
  }, [onStart])

  const onReadyCallback = useCallback(() => {
    setVideoIsDOMReady(true)
    onDOMReady?.()
  }, [onDOMReady])

  const onPauseCallback = useCallback(() => {
    setVideoPaused(true)

    if (hasEventTracking) {
      trackVideoPause(tracker, { videoName })
    }

    onPause?.()
  }, [onPause, hasEventTracking, videoName])

  const onPlayCallback = useCallback(() => {
    setVideoPaused(false)

    if (hasEventTracking) {
      trackVideoPlay(tracker, { videoName })
    }

    onPlay?.()
  }, [onPlay, hasEventTracking, videoName])

  const onBuffer = () => {
    setVideoIsLoading(true)
  }

  const onBufferEnd = () => {
    setVideoIsLoading(false)
    if (!videoIsReady) {
      setVideoIsReady(true)
      onReady?.()
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (!videoIsDOMReady) {
      return
    }

    setVideoIsLoading(false)

    if (!isAutoplay) {
      setVideoPaused(true)
    } else {
      setVideoPaused(false)
    }
  }, [isAutoplay, videoIsDOMReady])

  useIsomorphicLayoutEffect(() => {
    setVideoState?.({
      videoIsLoading,
      videoIsFullScreen,
      videoStarted,
      videoMuted,
      videoPaused,
      videoControls,
      videoProgress,
      videoDuration,
      videoIsHover,
      videoIsReady,
      videoIsDOMReady,
      videoHandlePausePlay,
      videoHandleMuteUnmute,
      videoHandleSeekTo,
      videoHandleFullScreen,
    })
  }, [
    videoIsFullScreen,
    videoIsLoading,
    videoProgress,
    videoStarted,
    videoMuted,
    videoPaused,
    videoControls,
    videoDuration,
    videoIsHover,
    videoIsReady,
    videoIsDOMReady,
  ])

  const props = {
    hasPlaysInline,
    videoPaused,
    videoClassName,
    videoRef,
    onStart: onStartCallback,
    onDurationChange,
    onEnded: onEndedCallback,
    onPause: onPauseCallback,
    onPlay: onPlayCallback,
    updateProgress,
    onReady: onReadyCallback,
    onClick: onClick,
    src,
    loop,
    onBuffer,
    onBufferEnd,
    controls: videoControls,
    isMuted: videoMuted,
  }

  const { targetRef, parentRef, processedCss } = useMathFitStyle(
    { type: 'cover' },
    [videoIsReady],
  )

  const [ratioContainerRef, { width: widthRatioContainer }] = useMeasure()

  return (
    <div
      className={cx(css.VideoPlayer, css[`${theme}Theme`], className)}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}>
      <>
        {isConstrained ? (
          <Ratio
            ref={ratioContainerRef}
            className={cx({ isFullScreen: videoIsFullScreen })}
            ratio={ratio ?? css.ratio}>
            {(style) => (
              <div ref={parentRef} style={style} className={css.videoContainer}>
                <VideoPlayerYoutube
                  forwardRef={targetRef}
                  style={processedCss}
                  {...props}
                  // Put the width of the component inside the width of the video loaded
                  width={widthRatioContainer > 0 ? widthRatioContainer : '100%'}
                  // Calcultate the height of the video from the width of the component multiply by the ratio of the real video
                  height={
                    widthRatioContainer > 0
                      ? widthRatioContainer * (1 / videoRatio)
                      : '100%'
                  }>
                  {children}
                </VideoPlayerYoutube>
              </div>
            )}
          </Ratio>
        ) : (
          <VideoPlayerYoutube className={css.videoInner} {...props}>
            {children}
          </VideoPlayerYoutube>
        )}
      </>
    </div>
  )
}

VideoPlayer.defaultProps = {
  controls: true,
  hasPlaysInline: false,
  hasEventTracking: false,
  isConstrained: true,
  isMuted: false,
  loop: false,
}

export default VideoPlayer
