import VideoControls, {
  MuteUnmuteButton,
  MuteUnmuteButtonProps,
  PlayPauseButton,
  PlayPauseButtonProps,
  ProgressBar,
  ProgressBarProps,
  VideoControlsProps,
} from './VideoControls'
import VideoPlayer, { VideoPlayerProps } from './VideoPlayer'
import VideoPlayerMock, { VideoPlayerMockProps } from './VideoPlayerMock'
import { useVideoState, VideoState } from './hooks'

export {
  MuteUnmuteButton, PlayPauseButton, ProgressBar,
  useVideoState, VideoControls, VideoPlayerMock
}

export type {
  MuteUnmuteButtonProps, PlayPauseButtonProps, ProgressBarProps, VideoControlsProps, VideoPlayerMockProps, VideoPlayerProps, VideoState
}

export default VideoPlayer
